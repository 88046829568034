import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/pro-solid-svg-icons/faPlay"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageMeta from "../components/ImageMeta"
import MarkdownViewer from "@components/MarkdownViewer"
import Seamless from "../components/SeamlessIframe"
import MenuLink from "../components/NavigationBar/MenuLink"
import Button from "../components/Buttons/Button"
import ButtonGroupMap from "../components/Buttons/ButtonGroupMap"
import TheaterVideo from "../components/Theater/TheaterVideo"
import QuoteSlider from "../components/QuoteSlider/QuoteSlider"

import Sizzle_Desktop from "../videos/sizzle_desktop.mp4"
import Success_Sizzle from "../videos/success_sizzle.mp4"
import Why_Sixstar_Sizzle from "../videos/why_sixstar_sizzle.mp4"

const Homepage = ({ pageContext, data }) => {
  const post = data.allUniquePagesJson.nodes[0]

  const clickTheater = () => {
    const theaterButton = document.querySelector(".hero-video")
    theaterButton.click()
  }

  //const getStartedFrom = data.forms.nodes[0].allSeamlessForms.find(form => form.title.toLowerCase() === "get started page")
  return (
    <Layout
      className="main-homepage"
      customNavButton={post.customNavButton}
      footerType={post.footerType}
    >
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={null}
      />

      {/* Hide button. Currently doesnt work in absolute containers. 
      Trigger it with the "clickTheater" function  */}
      <TheaterVideo
        videoUrl={`https://www.youtube.com/watch?v=${post.hero.card.button.youtube}`}
        controls
        playing
        onlyButton
        language={"en"}
        buttonClass="hidden hero-video"
      />
      <div className="home-hero">
        <div
          className="home-hero__sizzle"
          dangerouslySetInnerHTML={{
            __html: `
              <video 
                autoplay
                loop
                muted
                playsinline
                width="100%"
              >
                <source src="${Sizzle_Desktop}" type="video/mp4" />
              </video>`
          }}
        />
        <div className="home-hero__content">
          <h1>{post.hero.card.heading}</h1>
          <p className="home-hero__content--quote">
            <span className="home-hero__left-quote">“</span>
            {post.hero.card.quote}
            <span className="home-hero__right-quote">”</span>
          </p>
          {/* <TheaterVideo
            videoUrl={`https://www.youtube.com/watch?v=${post.hero.card.button.youtube}`}
            controls
            playing
            onlyButton
            language={"en"}
            buttonClass="hidden"
          /> */}
          <a
            aria-label="open video player"
            type="button"
            className="standard-button contained button-video"
            onClick={clickTheater}
          >
            <FontAwesomeIcon className="button-icon" icon={faPlay} />
            Watch Video
          </a>
        </div>
      </div>

      <div className="section">
        <div className="container medium">
          <div className="columns home-results">
            <div className="column is-three-fifths">
              <MarkdownViewer markdown={post.results.content} />
            </div>
            <div className="column relative">
              <ImageMeta
                data-aos="fade-left"
                data-aos-duration="1200"
                publicId={post.results.imageId}
                cloudName="nuvolum"
                width="auto"
                responsive
                responsiveUseBreakpoints="true"
                className="results-iphone"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section color-back">
        <div className="container">
          <div className="vertical-icon-text">
            {post.iconsWithText.map((item, i) => (
              <div key={i} className="vertical-icon-text__card">
                <ImageMeta
                  publicId={item.imageId}
                  cloudName="nuvolum"
                  width="auto"
                  responsive
                  responsiveUseBreakpoints="true"
                />
                <MarkdownViewer markdown={item.content} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="columns is-vcentered">
        <div className="column">
          <div
            dangerouslySetInnerHTML={{
              __html: `
                <video 
                  autoplay
                  loop
                  muted
                  playsinline
                  width="100%"
                >
                  <source src="${Why_Sixstar_Sizzle}" type="video/mp4" />
                </video>`
            }}
          />
        </div>
        <div className="column">
          <div className="section why-sixstar">
            <MarkdownViewer markdown={post.whySixtar.content} />
            <ButtonGroupMap buttons={post.whySixtar.buttons} />
          </div>
        </div>
      </div>

      <div className="section color-back gray">
        <div className="container">
          <h2 className="has-text-centered">
            {post.sixstarDifference.heading}
          </h2>

          <div className="difference-cards-container">
            {post.sixstarDifference.cards.map((item, i) => (
              <div key={i} className="difference-card">
                <ImageMeta
                  publicId={item.icon}
                  cloudName="nuvolum"
                  width="auto"
                  responsive
                  responsiveUseBreakpoints="true"
                />
                <h5>{item.heading}</h5>
                <p>{item.blurb}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="section color-back slider-section">
        <QuoteSlider quotes={post.quoteSlider} />
      </div>

      <div className="section color-back gray">
        <div className="container columns">
          <div className="column is-half">
            <MarkdownViewer markdown={post.success.content} />
            <ButtonGroupMap isCenteredMobile buttons={post.success.buttons} />
          </div>
          <div className="column is-2"></div>
          <div className="column is-half mt-1--mobile">
            <div
              dangerouslySetInnerHTML={{
                __html: `
                  <video 
                    autoplay
                    loop
                    muted
                    playsinline
                    width="100%"
                  >
                    <source src="${Success_Sizzle}" type="video/mp4" />
                  </video>`
              }}
            />
          </div>
        </div>
      </div>

      <div className="section get-started" id="form">
        <div className="container columns">
          <div className="column is-half">
            <MarkdownViewer markdown={post.getStarted.content} />
          </div>
          <div className="column is-2"></div>
          <div className="column mt-1--mobile">
            {/* <SeamlessIframe
                className="short-form--mobile"
                src={getStartedFrom.iframeSrc}
              /> */}
            <Seamless src="https://secureform.seamlessdocs.com/f/63c402cfd1802c6e4e2851be922684ee?embedded=true" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

Homepage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object
}

export const pageQuery = graphql`
  query basicquery {
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          card {
            heading
            quote
            button {
              appearance
              youtube
            }
          }
        }
        results {
          content
          imageId
        }
        iconsWithText {
          content
          imageId
        }
        whySixtar {
          content
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
        sixstarDifference {
          heading
          cards {
            heading
            blurb
            icon
          }
        }
        quoteSlider {
          quote
          name
          imageId
        }
        success {
          content
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
        getStarted {
          content
        }
        customNavButton {
          title
          href
          type
          hasButton
        }
        footerType
      }
    }
    forms: allDataJson(filter: { optionsItem: { eq: "forms" } }) {
      nodes {
        allSeamlessForms {
          iframeSrc
          title
        }
      }
    }
  }
`

export default Homepage
